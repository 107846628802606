

































































































import { DateSelectArg } from '@fullcalendar/vue';
import { User } from '@supabase/supabase-js';
import { format, subDays } from 'date-fns';
import { Vue, Component, Prop } from 'vue-property-decorator';

import { deleteEvent, Event, upsertEvent } from '@/plugins/supabase';

@Component
export default class EventFormModal extends Vue {
  @Prop({ default: null })
  user!: User | null;

  dialog = false;

  isEditable = false;
  isEditing = false;
  isValid = false;
  isDeleting = false;
  isSubmitting = false;

  creator = '';
  event: Event | null = null;

  form = {
    title: '',
    allDay: true,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  };

  titleRules = [(v: string) => !!v || 'Title is required'];

  dateRules = [
    (v: string) => !!v || 'Date is required',
    (v: string) => v.length === 10 || 'Date must be 10 characters',
  ];

  timeRules = [
    (v: string) => !!v || 'Time is required',
    (v: string) => v.length === 5 || 'Time must be 5 characters',
  ];

  reset() {
    this.isEditing = false;
    this.isEditable = false;
    this.isDeleting = false;
    this.isSubmitting = false;
    this.event = null;
    this.creator = '';
    this.form = {
      title: '',
      allDay: true,
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
    };

    (this.$refs.form as any)?.resetValidation();
  }

  formatDate(date: Date | string) {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return format(date, 'yyyy-MM-dd');
  }

  formatTime(date: Date | string) {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return format(date, 'HH:ss');
  }

  setFormDateTime(start: Date | string, end: Date | string) {
    let endDate = new Date(end);
    if (this.form.allDay && !this.event) {
      endDate = subDays(endDate, 1);
    }

    this.form.startDate = this.formatDate(start);
    this.form.startTime = this.formatTime(start);
    this.form.endDate = this.formatDate(endDate);
    this.form.endTime = this.formatTime(endDate);
  }

  openNew(arg?: DateSelectArg) {
    this.reset();
    this.isEditable = true;

    let start = new Date();
    let end = start;
    if (arg) {
      start = arg.start;
      end = arg.end;
      this.form.allDay = arg.allDay;
    }

    this.setFormDateTime(start, end);

    this.dialog = true;
    setTimeout(() => (this.$refs.titleInput as any).focus(), 250);
  }

  openEdit(event: Event) {
    this.reset();
    this.isEditing = true;
    this.isEditable = event.user_id === this.user?.id;
    this.event = event;

    this.form.title = event.title;
    this.form.allDay = event.all_day;
    this.setFormDateTime(event.start, event.end);

    this.creator = event.creator.name;

    this.dialog = true;
    if (this.isEditable) {
      setTimeout(() => (this.$refs.titleInput as any).focus(), 250);
    }
  }

  close() {
    this.reset();
    this.dialog = false;

    this.$emit('close');
  }

  async submit() {
    if (!this.isValid || !this.user) {
      return;
    }

    this.isSubmitting = true;

    const start = new Date(`${this.form.startDate} ${this.form.startTime}`);
    const end = new Date(`${this.form.endDate} ${this.form.endTime}`);

    const form: Partial<Event> = {
      user_id: this.user.id,
      id: this.event ? this.event.id : undefined,
      title: this.form.title,
      all_day: this.form.allDay,
      start: start.toJSON(),
      end: end.toJSON(),
    };

    const { data, error } = await upsertEvent(form);
    if (!data || error) {
      alert('Error, please contact admin (amy.azmim@gmail.com).');
    }

    this.close();
    this.isSubmitting = false;
  }

  async deleteEvent() {
    if (!this.event) {
      return;
    }

    this.isDeleting = true;

    const confirmed = confirm('Are you sure you wanna delete this event?');
    if (confirmed) {
      await deleteEvent(this.event.id);
      this.close();
    }

    this.isDeleting = false;
  }
}
