













































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { hex } from 'wcag-contrast';

import { Profile, updateProfile } from '@/plugins/supabase';

@Component
export default class EditProfileModal extends Vue {
  @Prop({ default: false })
  isNewUser!: boolean;

  @Prop({ default: '' })
  initialName!: string;

  @Prop({ required: true })
  profile!: Profile;

  @Prop({ default: false })
  value!: boolean;

  dialog = false;
  colorDialog = false;

  isValid = false;
  isSubmitting = false;

  registerKey = '';
  form = {
    name: '',
    color: '',
    text_color: '',
  };

  keyRules = [
    (v: string) => !!v || 'Register key is required',
    (v: string) =>
      v === process.env.VUE_APP_REGISTER_KEY ||
      'Invalid register key. Please contact admin.',
  ];

  nameRules = [
    (v: string) => !!v || 'Name is required',
    (v: string) => v.length <= 255 || 'Name must be less than 255 characters',
  ];

  colorRules = [
    (v: string) => !!v || 'Color is required',
    (v: string) => v.length === 7 || 'Color must be 7 characters',
    (v: string) => v.startsWith('#') || 'Color must start with "#" character',
  ];

  mounted() {
    this.form.name = this.profile.name;
    this.form.color = this.profile.color;
    this.form.text_color = this.profile.text_color;
    if (this.isNewUser) {
      this.form.name = this.initialName;
    }
  }

  cancel() {
    this.isSubmitting = false;
    this.dialog = false;
    if (this.isNewUser) {
      this.$emit('signOut');
    }
  }

  getTextColor() {
    const black = hex('#000000', this.form.color);
    const white = hex('#FFFFFF', this.form.color);

    return black > white ? '#000000' : '#FFFFFF';
  }

  async submit() {
    if (!this.isValid) {
      return;
    }

    this.isSubmitting = true;

    this.form.text_color = this.getTextColor();
    const { data, error } = await updateProfile(this.profile.id, this.form);
    if (!data || error) {
      alert('Error, please contact admin (amy.azmim@gmail.com).');
      this.cancel();
      return;
    }

    this.$emit('submitted', data);

    this.isSubmitting = false;
  }

  @Watch('value')
  onValueChanged(value: boolean) {
    this.dialog = value;
  }

  @Watch('dialog')
  onDialogChanged(dialog: boolean) {
    this.$emit('input', dialog);
  }
}
