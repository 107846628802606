






























































import { User } from '@supabase/supabase-js';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import EditProfileModal from '@/components/EditProfileModal.vue';
import supabase, { fetchProfile, Profile } from '@/plugins/supabase';

@Component({
  components: { EditProfileModal },
})
export default class AuthCard extends Vue {
  @Prop({ default: () => null })
  user!: User | null;

  isSigning = false;
  isLoadingProfile = false;
  isNewUser = false;

  showProfileDialog = false;

  profile: Profile | null = null;

  get userName() {
    if (!this.user) {
      return '';
    }

    if (this.profile?.name) {
      return this.profile.name;
    }

    return this.user.email;
  }

  get calendarUrl() {
    return process.env.VUE_APP_GCAL_URL;
  }

  signIn() {
    this.isSigning = true;

    supabase.auth.signIn({ provider: 'google' });
  }

  async signOut() {
    this.isSigning = true;

    await supabase.auth.signOut();

    this.isSigning = false;
  }

  async fetchProfile(id: string) {
    this.isLoadingProfile = true;

    const { data, error } = await fetchProfile(id);
    if (error || !data) {
      alert('Error, please contact admin (amy.azmim@gmail.com).');
      this.isLoadingProfile = false;
      return;
    }

    this.profile = data;
    if (this.profile.created_at === this.profile.updated_at) {
      this.isNewUser = true;
      this.$nextTick(() => (this.showProfileDialog = true));
    }

    this.isLoadingProfile = false;
  }

  profileUpdated(profile: Profile) {
    this.profile = profile;
    this.showProfileDialog = false;
    this.isNewUser = false;
  }

  @Watch('user')
  onUserChanged(user: User | null) {
    if (user) {
      this.fetchProfile(user.id);
    } else {
      this.profile = null;
    }
  }
}
