































import { RealtimeSubscription, User } from '@supabase/supabase-js';
import { Vue, Component } from 'vue-property-decorator';

import AppCalendar from '@/components/AppCalendar.vue';
import AuthCard from '@/components/AuthCard.vue';
import UsersCard from '@/components/UsersCard.vue';
import supabase, { fetchUsers, Profile } from '@/plugins/supabase';

@Component({
  components: { AppCalendar, AuthCard, UsersCard },
})
export default class App extends Vue {
  user: User | null = null;

  ppLink =
    'https://www.privacypolicygenerator.info/live.php?token=SW4Suzy0EfHipMzSegpgJdQRLWGbuial';
  tosLink =
    'https://www.termsofservicegenerator.net/live.php?token=VzIDM31aXiuGnSLZMQlBWUDqEM8t0dOm';

  isLoadingUsers = false;
  showAuth = true;

  users: Profile[] = [];
  usersListener: RealtimeSubscription | null = null;

  created() {
    const params = new URLSearchParams(location.search);
    this.showAuth = !params.has('noauth');

    if (params.has('redirect-pp')) {
      location.replace(this.ppLink);
    }
    if (params.has('redirect-tos')) {
      location.replace(this.tosLink);
    }
  }

  mounted() {
    this.user = supabase.auth.user();
    supabase.auth.onAuthStateChange(() => {
      this.user = supabase.auth.user();
    });

    this.fetchUsers();
  }

  beforeDestroy() {
    this.usersListener?.unsubscribe();
  }

  async fetchUsers() {
    this.isLoadingUsers = true;

    const { data } = await fetchUsers();
    if (data) {
      this.users = data;
    }

    this.usersListener = supabase
      .from<Profile>('users')
      .on('UPDATE', (payload) => {
        const newUser = payload.new;
        const user = this.users.find((v) => v.id === newUser.id);
        if (!user) {
          this.users.push(newUser);
          return;
        }

        user.name = newUser.name;
        user.color = newUser.color;
        user.text_color = newUser.text_color;
      })
      .subscribe();

    this.isLoadingUsers = false;
  }
}
