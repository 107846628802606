






























import { Vue, Component, Prop } from 'vue-property-decorator';

import { Profile } from '@/plugins/supabase';

@Component
export default class UsersCard extends Vue {
  @Prop({ default: () => [] })
  users!: Profile[];

  @Prop({ default: false })
  loading!: boolean;
}
