var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Edit Profile")]),_c('v-card-text',[(_vm.isNewUser)?_c('p',{staticClass:"black--text"},[_vm._v(" Hey, looks like you are a new user. Before continuing, please update your profile information in the form below. ")]):_vm._e(),_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[(_vm.isNewUser)?_c('v-text-field',{attrs:{"label":"Register Key","rules":_vm.keyRules},model:{value:(_vm.registerKey),callback:function ($$v) {_vm.registerKey=$$v},expression:"registerKey"}}):_vm._e(),_c('v-text-field',{attrs:{"counter":255,"label":"Name","rules":_vm.nameRules},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-dialog',{attrs:{"offset-y":"","close-on-content-click":false,"width":"290px","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"Event color","rules":_vm.colorRules},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{style:({
                    height: '20px',
                    width: '20px',
                    background: _vm.form.color,
                    'border-radius': '20px',
                  })})]},proxy:true}],null,true),model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.colorDialog),callback:function ($$v) {_vm.colorDialog=$$v},expression:"colorDialog"}},[_c('v-color-picker',{attrs:{"dot-size":"20"},model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.colorDialog = false}}},[_vm._v("OK")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.cancel}},[(_vm.isNewUser)?_c('span',[_vm._v("Cancel and Logout")]):_c('span',[_vm._v("Cancel")])]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.isSubmitting},on:{"click":_vm.submit}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }